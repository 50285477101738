import React, { useState, useEffect, useCallback } from "react";
import {
  Sword,
  ArrowRight,
  Dog,
  Shield,
  ExternalLink,
  ChevronLeft,
  ChevronRight,
  Snowflake,
  AlertCircle,
} from "lucide-react";

// Custom Card Components to replace shadcn/ui
const Card = ({ children, className = "" }: any) => (
  <div
    className={`bg-white rounded-lg border border-gray-200 shadow-sm ${className}`}
  >
    {children}
  </div>
);

const CardHeader = ({ children, className = "" }: any) => (
  <div className={`p-6 border-b border-gray-200 ${className}`}>{children}</div>
);

const CardContent = ({ children, className = "" }: any) => (
  <div className={`p-6 ${className}`}>{children}</div>
);

const CardTitle = ({ children, className = "" }: any) => (
  <h3 className={`text-lg font-semibold text-gray-900 ${className}`}>
    {children}
  </h3>
);

// Custom Alert Component to replace shadcn/ui
const Alert = ({ children, variant = "default", className = "" }: any) => {
  const variants = {
    default: "bg-gray-100 text-gray-900 border-gray-200",
    destructive: "bg-red-100 text-red-900 border-red-200",
  } as any;

  return (
    <div
      className={`rounded-lg border p-4 ${variants[variant]} ${className}`}
      role="alert"
    >
      {children}
    </div>
  );
};

// First, create a new Snowfall component
const SnowflakeComponent = ({ style }: any) => (
  <div
    className="absolute text-white animate-fall pointer-events-none"
    style={style}
  >
    •
  </div>
);

const SnowfallAnimation = () => {
  const [snowflakes, setSnowflakes] = useState([] as any);

  useEffect(() => {
    const createSnowflake = () => {
      const size = Math.random() * 3 + 2; // Size between 2-5px
      return {
        id: Math.random(),
        left: `${Math.random() * 100}%`,
        animationDuration: `${Math.random() * 3 + 2}s`, // Duration between 2-5s
        opacity: Math.random() * 0.6 + 0.4, // Opacity between 0.4-1
        size: `${size}px`,
      };
    };

    // Initialize with some snowflakes
    setSnowflakes(Array.from({ length: 50 }, createSnowflake));

    // Add new snowflakes periodically
    const interval = setInterval(() => {
      setSnowflakes((prev: any) => [...prev.slice(-49), createSnowflake()]);
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="fixed inset-0 overflow-hidden pointer-events-none z-50">
      {snowflakes.map((flake: any) => (
        <SnowflakeComponent
          key={flake.id}
          style={{
            left: flake.left,
            animation: `fall ${flake.animationDuration} linear infinite`,
            opacity: flake.opacity,
            fontSize: flake.size,
          }}
        />
      ))}
    </div>
  );
};

// Add this to your existing styles (can be added at the top of your file)
const styles = `
  @keyframes fall {
    0% {
      transform: translateY(-10px) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translateY(100vh) rotate(360deg);
      opacity: 0.4;
    }
  }

  .animate-fall {
    will-change: transform;
  }
`;

const SocialLinks = () => (
  <div className="w-full bg-black text-amber-300 py-3 px-4">
    <div className="max-w-6xl mx-auto flex flex-wrap items-center justify-between gap-4">
      <div className="flex items-center space-x-6">
        <a
          href="https://x.com/jakesnowxyz"
          className="flex items-center hover:text-amber-400 transition-colors"
        >
          <Dog className="h-4 w-4 mr-2" />X
        </a>
      </div>
      <a
        href="https://solscan.io/token/mZhwU6A69SD92NjUDdpmmREfKpMCjoxYZZoAFpzpump"
        className="flex items-center hover:text-amber-400 transition-colors"
      >
        <span>View on Solscan</span>
        <ExternalLink className="h-4 w-4 ml-2" />
      </a>
    </div>
  </div>
);

const MemeCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    `${process.env.PUBLIC_URL}/jake-snow-1.webp`,
    `${process.env.PUBLIC_URL}/jake-snow-2.webp`,
    `${process.env.PUBLIC_URL}/jake-snow-3.webp`,
  ];

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <div className="relative w-full max-w-2xl mx-auto">
      <div className="overflow-hidden rounded-xl aspect-video relative">
        <div
          className="flex transition-transform duration-500 ease-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`AI-Generated Solana Meme ${index + 1}`}
              className="w-full h-full object-cover flex-shrink-0"
            />
          ))}
        </div>
        <button
          onClick={prevSlide}
          className="absolute left-2 top-1/2 -translate-y-1/2 bg-black/50 text-white p-2 rounded-full"
        >
          <ChevronLeft className="h-6 w-6" />
        </button>
        <button
          onClick={nextSlide}
          className="absolute right-2 top-1/2 -translate-y-1/2 bg-black/50 text-white p-2 rounded-full"
        >
          <ChevronRight className="h-6 w-6" />
        </button>
      </div>
      <div className="flex justify-center mt-4 space-x-2">
        {images.map((_, index) => (
          <button
            key={index}
            className={`w-2 h-2 rounded-full ${
              index === currentIndex ? "bg-blue-500" : "bg-gray-300"
            }`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

const WinningPredictions = () => {
  const predictions = [
    { date: "2024-11-16", title: "Bitcoin passes $90k" },
    { date: "2024-11-15", title: "Jake Paul wins" },
    {
      date: "2024-11-01",
      title: "Trump Wins",
    },

    { date: "2024-10-29", title: "Solana AI Token Launches on Monday" },
    { date: "2024-10-27", title: "AI Meme Coin passes $0.1" },
  ];

  return (
    <div className="overflow-x-auto rounded-lg border border-gray-200">
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-50">
            <th className="p-4 text-left text-gray-600 border-b">Date</th>
            <th className="p-4 text-left text-gray-600 border-b">Prediction</th>
          </tr>
        </thead>
        <tbody>
          {predictions.map((pred, index) => (
            <tr
              key={index}
              className="border-b border-gray-200 hover:bg-gray-50 transition-colors"
            >
              <td className="p-4">{pred.date}</td>
              <td className="p-4 font-medium">{pred.title}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Footer = () => (
  <footer className="bg-slate-900 text-white py-12 px-4">
    <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
      <div>
        <h3 className="text-xl font-bold mb-4">Jake Snow</h3>
        <p className="text-slate-400">
          Mathematical predictions from beyond the Wall, homie!
        </p>
      </div>
      <div>
        <h3 className="text-xl font-bold mb-4">Quick Links</h3>
        <ul className="space-y-2 text-slate-400">
          <li>
            <a
              href="https://x.com/jakesnowxyz"
              className="hover:text-white transition-colors"
            >
              X
            </a>
          </li>
          <li>
            <a
              href="https://solscan.io/token/mZhwU6A69SD92NjUDdpmmREfKpMCjoxYZZoAFpzpump"
              className="hover:text-white transition-colors"
            >
              Solscan
            </a>
          </li>
        </ul>
      </div>
      <div>
        <h3 className="text-xl font-bold mb-4">Join the AI Revolution</h3>
        <p className="text-slate-400">
          Experience the power of AI memes and Solana predictions beyond the
          Wall!
        </p>
      </div>
    </div>
    <div className="max-w-6xl mx-auto mt-8 pt-8 border-t border-slate-800 text-center text-slate-400">
      <p>
        &copy; 2024 Jake Snow AI. Artificial Intelligence meets Mathematical
        Memes on Solana!
      </p>
    </div>
  </footer>
);

const LandingPage = () => {
  const [error, setError] = useState(null);
  const [activeSection, setActiveSection] = useState("home");

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY;
    const sections = ["home", "features", "predictions", "memes"];

    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (element) {
        const { offsetTop, offsetHeight } = element;
        if (
          scrollPosition >= offsetTop &&
          scrollPosition < offsetTop + offsetHeight
        ) {
          setActiveSection(section);
        }
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  if (error) {
    return (
      <div className="m-4">
        <Alert variant="destructive">
          <div className="flex items-center">
            <AlertCircle className="h-4 w-4 mr-2" />
            <div>
              <div className="font-medium">Error</div>
              <div className="text-sm">{error}</div>
            </div>
          </div>
        </Alert>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-slate-900">
      <style>{styles}</style>
      <SnowfallAnimation />

      <SocialLinks />

      {/* Hero Section with New Image */}
      <section
        id="home"
        className="relative min-h-screen flex flex-col items-center justify-start px-4"
      >
        {/* Full-width image container */}
        <div className="w-full h-[70vh] relative overflow-hidden">
          <img
            src={`${process.env.PUBLIC_URL}/main-image.webp`}
            alt="Jake Snow of the Night's Watch"
            className="w-full h-full object-cover object-center"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent" />
        </div>

        {/* Content overlaid on image */}
        <div className="relative z-10 text-center px-4 -mt-20">
          <div className="animate-in fade-in slide-in-from-bottom duration-700">
            <h1 className="text-4xl md:text-6xl font-bold text-amber-300 mb-6">
              Jake Snow: The Mathematical Watcher
            </h1>
            <p className="text-xl text-amber-100 max-w-2xl mx-auto mb-8">
              Algebraic AI predictions from beyond the Wall! You know nothing...
              until you check our signals!
            </p>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="py-20 px-4 bg-black/80">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            {
              icon: <Sword className="h-8 w-8 text-amber-500" />,
              title: "Valyrian Steel Signals",
              description:
                "Sharp as Longclaw, our bot's AI-powered predictions cut through the noise",
            },
            {
              icon: <Shield className="h-8 w-8 text-amber-500" />,
              title: "Night's Watch Protection",
              description: "Guarding your investments against the long night",
            },
            {
              icon: <Snowflake className="h-8 w-8 text-amber-500" />,
              title: "Winter-Proof Strategy",
              description:
                "Mathematical AI-driven strategies that work in any season",
            },
          ].map((feature, index) => (
            <Card
              key={index}
              className="transform hover:scale-105 transition-transform duration-200 bg-gray-900 border-amber-400/20"
            >
              <CardHeader className="border-b border-amber-400/20">
                <div className="mb-4">{feature.icon}</div>
                <CardTitle className="text-amber-300">
                  {feature.title}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-amber-500">{feature.description}</p>
              </CardContent>
            </Card>
          ))}
        </div>
      </section>

      {/* Predictions Section */}
      <section id="predictions" className="py-20 px-4 bg-gray-900">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12 text-amber-300">
            Mathematical Victories
          </h2>
          <div className="bg-black/50 rounded-lg p-4">
            <WinningPredictions />
          </div>
        </div>
      </section>

      {/* Memes Section */}
      <section id="memes" className="py-20 px-4 bg-black/80">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6 text-amber-300">
            Beyond the Wall Memes
          </h2>
          <p className="text-amber-100/80 mb-12">
            Fresh mathematical AI-generated memes from the Night's Watch!
          </p>
          <MemeCarousel />
        </div>
      </section>

      {/* Social Proof Section */}
      <section className="py-20 px-4 bg-gray-900">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-12 text-amber-300">
            Trusted by the AI & Solana Community
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <Card className="bg-black/50 border-amber-400/20">
              <CardContent>
                <p className="text-amber-500 mb-4">
                  "Jake's AI signals are insane! Called the BONK pump perfectly
                  - made 4x thanks to the meme coin predictions. The AI doesn't
                  miss!"
                </p>
                <p className="font-medium text-amber-800">@SolanaWhale</p>
                <p className="text-sm text-amber-500">
                  Solana DeFi Trader • 50k+ followers
                </p>
              </CardContent>
            </Card>

            <Card className="bg-black/50 border-amber-400/20">
              <CardContent>
                <p className="text-amber-500 mb-4">
                  "The AI meme generator is actually genius. Created a viral
                  meme that got 100k+ impressions. Plus the Solana trading
                  signals are pure alpha!"
                </p>
                <p className="font-medium text-amber-800">CryptoMemeKing</p>
                <p className="text-sm text-amber-500">
                  NFT Artist • Meme Creator
                </p>
              </CardContent>
            </Card>

            <Card className="bg-black/50 border-amber-400/20">
              <CardContent>
                <p className="text-amber-500 mb-4">
                  "Been trading SOL for 3 years - never seen anything like this
                  AI system. The mathematical predictions are scary accurate.
                  IYKYK 👀"
                </p>
                <p className="font-medium text-amber-800">Maria K.</p>
                <p className="text-sm text-amber-500">
                  Verified Solana Developer
                </p>
              </CardContent>
            </Card>

            <Card className="bg-black/50 border-amber-400/20">
              <CardContent>
                <p className="text-amber-500 mb-4">
                  "Their AI caught the SAMO pump hours before it happened. Made
                  my yearly salary in a week. The memes are just a bonus tbh 🚀"
                </p>
                <p className="font-medium text-amber-800">DegenTrader.sol</p>
                <p className="text-sm text-amber-500">
                  Full-time Solana Trader
                </p>
              </CardContent>
            </Card>

            <Card className="bg-black/50 border-amber-400/20">
              <CardContent>
                <p className="text-amber-500 mb-4">
                  "Not just another AI project - these guys actually deliver.
                  The meme economy signals are worth the subscription alone.
                  Easy 10x."
                </p>
                <p className="font-medium text-amber-800">Alexis R.</p>
                <p className="text-sm text-amber-500">
                  Crypto Fund Manager • AI Researcher
                </p>
              </CardContent>
            </Card>

            <Card className="bg-black/50 border-amber-400/20">
              <CardContent>
                <p className="text-amber-500 mb-4">
                  "Been following Jake since day 1. The new AI integration is
                  next level - called every major Solana move this year. Based
                  team 🔥"
                </p>
                <p className="font-medium text-amber-800">@SolanaMaxi</p>
                <p className="text-sm text-amber-500">OG Community Member</p>
              </CardContent>
            </Card>

            <Card className="bg-black/50 border-amber-400/20">
              <CardContent>
                <p className="text-amber-500 mb-4">
                  "Their artificial intelligence model is revolutionary. As a
                  data scientist, I'm impressed by the accuracy. Plus the memes
                  are god tier!"
                </p>
                <p className="font-medium text-amber-800">Dr. Sarah Chen</p>
                <p className="text-sm text-amber-500">
                  AI Researcher • Solana Labs
                </p>
              </CardContent>
            </Card>

            <Card className="bg-black/50 border-amber-400/20">
              <CardContent>
                <p className="text-amber-500 mb-4">
                  "My meme page blew up using their AI generator. 0 to 100k
                  followers in 2 months. The Solana predictions are just the
                  cherry on top!"
                </p>
                <p className="font-medium text-amber-800">MemeVault</p>
                <p className="text-sm text-amber-500">
                  Content Creator • 100k+ Following
                </p>
              </CardContent>
            </Card>

            <Card className="bg-black/50 border-amber-400/20">
              <CardContent>
                <p className="text-amber-500 mb-4">
                  "Best AI trading community in Solana. No cap. The mathematical
                  models combined with meme sentiment analysis is genius. LFG!
                  🚀"
                </p>
                <p className="font-medium text-amber-800">CryptoNinja</p>
                <p className="text-sm text-amber-500">
                  Pro Trader • Signal Provider
                </p>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default LandingPage;
